export default { 
  h0e16b2145bde74a69e7f3f5535f81774: [[({
  brand: brand
}, []) => ["Enable Facial Recognition Login for Your ", brand.name, " Account."], {}]],
  h10c5c0b23bb1fd3e80e7cfdc02ae32e5: [[({
  brand: brand
}, []) => ["You now have the option of using Face ID\xAE to log in to the", " ", brand.name, " mobile app! Once you enable this option, login will be much easier and faster!"], {}]],
  h2a62f68aa06a7e7ab0859296619ad258: [[({}, []) => ["Face ID is a registered trademark of Apple. Inc."], {}]],
  h35fc3cd0e23045c40652c9f65f32826a: [[({}, [C0]) => [C0()], {}]],
  h3c06428f85af79328ef9289ec055f8f0: [[({
  brand: brand
}, []) => ["Your ", brand.name, " Account is a Fingerprint Away"], {}]],
  h88b2c35c90a54aa91f8a72fe77171c95: [[({
  brand: brand
}, []) => ["You are about to enable Fingerprint Login for quick access to the ", brand.name, " mobile app as an alternative to your username and password."], {}]],
  h90e2a9b9e893d69ac3bf3dc9e34598b5: [[({}, [C0, C1]) => ["Simply check the Enable", " ", C0(), " Login checkbox on the Login page and follow the instructions. If you haven\u2019t already, you will also need to enable", " ", C1(), " ", "in your device\u2019s settings."], {}]],
  h91921f1484949f0e47c574d6f7b7ca19: [[({}, []) => ["REMEMBER: Anyone with a stored fingerprint on this device will have access to your Online Account."], {}]],
  hb23a0b8952aceadec8ff8762e3dae7c7: [[({}, []) => ["Cancel"], {}]] 
}