import __NS_DYNAMIC_REACT_CONTENT___0 from "./biometrics-security-path-content.strings/content.default.js";
import { Content, createScopedReactContentImporter } from "packages/react-content/runtime";
const NS_DYNAMIC_REACT_CONTENT_MANAGER = createScopedReactContentImporter(undefined, {
  "default": () => __NS_DYNAMIC_REACT_CONTENT___0
});
import { createElement, Fragment } from 'react';
export const BiometricsSecurityPathDashboard = ({
  dashboardMfeEnabled,
  loginPreferencesMfeEnabled
}) => {
  return dashboardMfeEnabled ? createElement(Fragment, null, loginPreferencesMfeEnabled ? createElement(Content, {
    hash: "6cc5b0efd8a1bde22c200f256a69ecf9",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }) : createElement(Content, {
    hash: "d0f4515a0f212d6bec69ff2347c4bcb8",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })) : createElement(Content, {
    hash: "017ff87e5dba81c5577b6edbadc7a7fc",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  });
};