import __NS_DYNAMIC_REACT_CONTENT___0 from "./acp-biometrics-esign.strings/content.default.js";
import { Content, createScopedReactContentImporter } from "packages/react-content/runtime";
const NS_DYNAMIC_REACT_CONTENT_MANAGER = createScopedReactContentImporter(undefined, {
  "default": () => __NS_DYNAMIC_REACT_CONTENT___0
});
import { createElement, Fragment } from 'react';
import { InPageFlowLayout } from 'legos/in-page-flow-layout';
import { ContentBlock } from 'legos/content-block';
import { Body, Bold, LinkBody, Title } from 'legos/typography';
import { EsignLayout, MrgTop, CommunicationLayout, LiLayout } from './styles/acp-biometrics-esign.ccm.css';
import { useExternalLinkHandler } from 'packages/external-link-opener/react';
import { useAcpBiometricsTheme } from '../app.theme';
export const BiometricsEsign = ({
  title,
  cancelAnchor,
  children
}) => {
  return createElement(InPageFlowLayout, {
    title: title,
    cancelAnchor: cancelAnchor
  }, createElement(ContentBlock, null, createElement(Title, null, "E-Sign Consent"), createElement(CommunicationLayout.div, null, createElement(Body, null, "Communications will be sent to the following e-mail address we have on file for you. You can change your email address below."), children)));
};
export const EsignDisclosureContent = ({
  cardType,
  esignProductName,
  brand
}) => {
  const {
    lightDisclosureGreyColor,
    neutralBlackColor
  } = useAcpBiometricsTheme();
  return createElement(EsignLayout.div, {
    $borderColor: lightDisclosureGreyColor
  }, createElement(MrgTop.div, null, createElement(Body, null, createElement(Content, {
    hash: "d147618f180c0f7bb21cff8d20830bf1",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }))), createElement(Body, null, createElement(Content, {
    hash: "47ee22e0abecc050f9c39e9a277b2668",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "1ae6a29f632c182ff5d40d7774b6d36b",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "509196fcf79ecb956748a5bea5abc48c",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children), () => esignProductName ? esignProductName : createElement(Content, {
      hash: "ff5d3e15c9a2692571c3f017dd021db6",
      devVariables: {
        cardType: cardType
      },
      componentsAndExpressions: [],
      importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
    }), (...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children), () => esignProductName ? esignProductName : createElement(Content, {
      hash: "5c4a32a15ae518840bc1364ceeb476d3",
      devVariables: {
        cardType: cardType
      },
      componentsAndExpressions: [],
      importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
    })],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "1cb0db3c6eb8501e97d6ebe33abca398",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Fragment, null, createElement("ul", null, createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "0f07bf59853c18b78681dc283b6e9ba2",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "ea766533ef622dbd269356a1a1d5d5cf",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "adee9beda8f41c0ce1119626a91124b3",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "b25d230aecc154d300d3e0f9c165826e",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "52f785b6abcd025b5793ad565db0e372",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })))), createElement(Body, null, createElement(Content, {
    hash: "741f5a74a5c3e7f0fe020ac70b77566c",
    devVariables: {
      brand: brand
    },
    componentsAndExpressions: [(...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "801ab7240e7eed1c71dfdf79c311645c",
    devVariables: {
      brand: brand
    },
    componentsAndExpressions: [(...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "bc87c8aa4968b470e6725b841ace6d14",
    devVariables: {
      brand: brand
    },
    componentsAndExpressions: [(...children) => createElement(Bold, null, children), (...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "352a9078107a254a5091b716c15e3ed3",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Fragment, null, createElement("ul", null, createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "229986aa7f0ec60de77e982f02bf7987",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "7434577a0c7143fced621890f1624a7f",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "4468f68e0e6fd8bd40ec4351fb58cfe5",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "9ff2b65f2b6d99fcfc3f7aa7b06d5082",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(LiLayout.li, {
    $textColor: neutralBlackColor
  }, createElement(Content, {
    hash: "222e97ec11efa69c1cbbf1aeaecedac9",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })))), createElement(Body, null, createElement(Content, {
    hash: "a1f731bd1ecd8d6c92c225e5b306e032",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }), createElement(Bold, null, createElement(LinkBody, useExternalLinkHandler(`tel:${brand.phone}`), brand.phone_formatted)), ' ', createElement(Content, {
    hash: "c2b39b646c1a2aa14dcc0ffa7a6ecce2",
    devVariables: {
      brand: brand
    },
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "c9592c47c3c6c49c02c3df716080358d",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "fac437e9a5b9da92d17bbcf5670badff",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(Body, null, createElement(Content, {
    hash: "50a0341cfe1b8284412cddb7de45cbf7",
    devVariables: {},
    componentsAndExpressions: [(...children) => createElement(Bold, null, children)],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })));
};