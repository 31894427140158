import __NS_DYNAMIC_REACT_CONTENT___0 from "./acp-biometrics-consent.strings/content.default.js";
import { Content, createScopedReactContentImporter } from "packages/react-content/runtime";
const NS_DYNAMIC_REACT_CONTENT_MANAGER = createScopedReactContentImporter(undefined, {
  "default": () => __NS_DYNAMIC_REACT_CONTENT___0
});
import { SectionHeaderOne, Title } from 'legos/typography';
import { createElement, Fragment } from 'react';
import { IntroductionConsentDisclosure, IframeLayout } from './styles/acp-biometrics-consent.ccm.css';
import { ContentBlock } from 'legos/content-block';
import { Iframe } from '../acp-iframe/index';
import { useAcpBiometricsTheme } from '../app.theme';
import { InPageFlowLayout } from 'legos/in-page-flow-layout';
import { SendEventAnalytics } from 'apps/acp/packages/react-analytics';
import { BIOMETRICS_ANALYTICS_CONSTANTS } from '../acp-biometrics-ga-analytics';
import { useSendAnalytics } from 'packages/react-analytics';
export const BiometricsConsent = ({
  children,
  title,
  iframeProp,
  backLinks
}) => {
  const {
    lightDisclosureGreyColor
  } = useAcpBiometricsTheme();
  const sendAnalytics = useSendAnalytics();
  const moveBack = {
    /**
     * This will help in capture send analytics event
     * when clicked on InPageFlowLayout back button.
     */
    href: '#',
    onClick: e => {
      e.preventDefault();
      sendAnalytics({
        event: BIOMETRICS_ANALYTICS_CONSTANTS.ACTIONS.TERMS.DISMISS,
        payload: {
          category: BIOMETRICS_ANALYTICS_CONSTANTS.CATEGORY,
          label: BIOMETRICS_ANALYTICS_CONSTANTS.LABEL
        }
      });
      history.replaceState(null, '', backLinks.url());
    }
  };
  return createElement(Fragment, null, createElement(SendEventAnalytics, {
    analyticsEvent: {
      event: BIOMETRICS_ANALYTICS_CONSTANTS.ACTIONS.TERMS.VIEWED,
      payload: {
        category: BIOMETRICS_ANALYTICS_CONSTANTS.CATEGORY,
        label: BIOMETRICS_ANALYTICS_CONSTANTS.LABEL
      }
    }
  }), createElement(InPageFlowLayout, {
    title: title,
    cancelAnchor: moveBack
  }, createElement(ContentBlock, null, createElement(Title, null, createElement(Content, {
    hash: "b3c1acb532fc04b182421bd3fb5e521b",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  })), createElement(SectionHeaderOne, null, createElement(IntroductionConsentDisclosure.div, {
    $textColor: lightDisclosureGreyColor
  }, createElement(Content, {
    hash: "7cafbf9b1fd7770b90b4f902ce925d66",
    devVariables: {},
    componentsAndExpressions: [],
    importer: NS_DYNAMIC_REACT_CONTENT_MANAGER
  }))), createElement(IframeLayout.div, {
    $borderColor: lightDisclosureGreyColor
  }, createElement(Iframe, iframeProp)), children)));
};